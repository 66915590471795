import {
    CircularProgress,
    Button,
    Badge,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { getNotificationCount } from '../services/notify'

export default function Notify({ user }) {
    const [unreadCount, setUnreadCount] = useState(0);

    const [refreshInterval, setRefreshInterval] = useState(15000);
    const [onStart, setOnStart] = useState(true)

    const [isLoading, setIsLoading] = useState();
    const [errorMessage, setErrorMessage] = useState();

    useEffect(() => {
        if (onStart) {
            setOnStart(false)
            handleRefresh()
        }
        if (refreshInterval && refreshInterval > 0) {
            const interval = setInterval(handleRefresh, refreshInterval);
            return () => clearInterval(interval);
        }
    }, [refreshInterval]);

    const handleRefresh = () => {
        loadTasks(user)
    }

    const loadTasks = async (user) => {
        setIsLoading(true);
        await getNotificationCount(user)
            .then(response => {
                // console.log(response)

                if (response.status == 'success') {
                    setUnreadCount(response.result.unreadCount);
                } else {
                    setErrorMessage(response.message);
                }

            })
            .catch(error => {
                setErrorMessage(error.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return (
        <Button aria-label="Powiadomienia" component={Link} to="/notifications" >
            <Badge color="primary" badgeContent={unreadCount} max={9}>
                <NotificationsIcon color='action' />
            </Badge>
        </Button>
    )
}