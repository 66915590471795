import React from 'react';
import theme from '../../theme';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Divider,
  Button, Typography
} from '@mui/material';
import Decision from './Decision'

const ReusableDialog = ({
  user,
  isOpen,
  handleAccept,
  handleReject
}) => {

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleAccept}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: {
          minHeight: '70%',
          maxHeight: '90%',
        }}}
      >
        <DialogTitle id="alert-dialog-title"> Wysyłanie zadania </DialogTitle>
        <DialogContent>
          <Decision
            user={user}
          />
        </DialogContent>

      </Dialog>
    </>
  );
};

export default ReusableDialog;