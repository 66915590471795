import React, { useState, useEffect } from 'react';
import theme from '../../theme';
import {
    Card, CardContent, Typography, Avatar, Chip, Box, Divider,
    Grid, Button, ListItem, Alert, CircularProgress,
} from '@mui/material';

import FaceIcon from '@mui/icons-material/Face';
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ManIcon from '@mui/icons-material/Man';
import HelpIcon from '@mui/icons-material/Help';
import Label from '../Label';
import { useNavigate } from "react-router-dom";
import ReusableDialog from '../ReusableDialog';
import { updateStation } from '../../services/station';

const StationListCard = ({ item, user, setUser }) => {

    const [takeOverStationDialogOpen, setTakeOverStationDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const color = item?.status == 'wolne' ? "success" : item?.id === user?.stationId ? "secondary" : "primary"
    const navigate = useNavigate();
    const handleOpen = async () => { // zajmowanie i przejmowanie stanowiska
        if (item?.id !== user?.stationId) {
            if (item?.status !== 'wolne') {
                console.log('przejmuje')
                setTakeOverStationDialogOpen(true)
            } else {
                console.log('zajmuje')
                setIsLoading(true);
                const result = await updateStation(user, 1, item.id)

                if (result?.status === 'error') {
                    setErrorMessage(result.message);
                    setIsLoading(false);
                } else {
                    setUser({ ...user, station: item?.class, stationId: item?.id })
                    setIsLoading(false);
                    navigate("/tasks", { params: { itemId: item.id, user: user } })
                }
            }
        } else { //zwalnianie stanowiska        
            console.log('zwalniam')
            setIsLoading(true);
            const result = await updateStation(user, 0, item.id)

            if (result?.status === 'error') {
                setErrorMessage(result.message);
                setIsLoading(false);
            } else {
                item.status = 'wolne'
                setUser({ ...user, station: null, stationId: null })
                setIsLoading(false);
                navigate('/')
            }
        }
    }

    // okienko - potwierdzacz przejęcia   
    const handleTakeOverStationDialogOnAccept = async (event) => {
        setIsLoading(true);
        const result = await updateStation(user, 2, item.id)
        setTakeOverStationDialogOpen(false)
        if (result?.status === 'error') {
            setErrorMessage(result.message);
            setIsLoading(false);
        } else {
            setUser({ ...user, station: item?.class, stationId: item?.id })
            setIsLoading(false);
            navigate("/tasks", { params: { itemId: item.id, user: user } })
        }
    };
    const handleTakeOverStationDialogOnReject = (event) => {
        setTakeOverStationDialogOpen(false)
    };

    return (
        <Card sx={{ maxWidth: '900px', minWidth: '600px', width: '90%', display: 'flex', margin: theme.spacing(1), backgroundColor: 'white' }}>
            <Grid item container sx={{ margin: theme.spacing(2), width: '10%', justifyContent: 'center', alignItems: 'center' }}>
                {item?.class == 'wash' ? <LocalCarWashIcon fontSize="large" color={color} /> :
                    item?.class == 'detailing' ? <VisibilityIcon fontSize="large" color={color} /> :
                        item?.class == 'master' ? <ManIcon fontSize="large" color={color} /> :
                            <HelpIcon fontSize="large" color={color} />}
            </Grid>
            <Grid item container sx={{ width: '90%', height: "100%", justifyContent: 'center', alignItems: 'center' }}>
                <Grid item container sx={{ margin: theme.spacing(0.1), backgroundColor: 'white' }}>
                    <Box sx={{ display: "flex", alignItems: "baseline", flexGrow: 1 }} >
                        <Typography color={"black"} variant="h6" gutterBottom>{item?.name}</Typography>
                    </Box>
                </Grid>
                <Grid item container sx={{ margin: theme.spacing(0.1), backgroundColor: 'white' }}>
                    <Box sx={{ display: "flex", alignItems: "baseline", flexGrow: 1 }} >
                        <Label data={{ label: "status", value: item?.status }} />
                    </Box>
                </Grid>

                <Grid item container sx={{ margin: theme.spacing(0.1), backgroundColor: 'white' }}>
                    <Box sx={{ display: "flex", alignItems: "baseline" }} >
                        <Label data={{ label: "ostatnio pracowałeś: ", value: item?.lastWorkedDate }} />
                    </Box>

                </Grid>
            </Grid>
            <Grid item container sx={{ margin: theme.spacing(2), justifyContent: 'right', alignItems: 'center' }}>
                {item?.status !== 'wolne' ? (<Chip icon={<FaceIcon />} label={"" + item?.busyByName + ", pracuje od " + item?.busyFrom + " min"} variant="outlined" size="small" color="primary" sx={{ margin: theme.spacing(0.2) }} />) : ''}
                {errorMessage && <div><Alert severity="error">{errorMessage}</Alert></div>}
            </Grid>

            <Grid item container sx={{ margin: theme.spacing(2), width: '15%', justifyContent: 'center', alignItems: 'center' }}>
                <Button disabled={!(item?.id === user?.stationId || user?.stationId == null)} onClick={handleOpen} variant="contained" color={color} sx={{ minWidth: "68px" }}>
                    {item?.status == 'wolne' ? "Wybieram" : item?.id === user?.stationId ? "Zwalniam" : "Przejmuje"}
                </Button>
                {isLoading && (
                    <CircularProgress
                        size={24}
                        sx={{
                            color: 'red',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                    />
                )}
                <ReusableDialog
                    title={'Potwierdź przejęcie stanowiska'}
                    subtitle={'Czy rzeczywiście chesz przejąć stanowisko ' + item?.name + '?'}
                    children={'Przejmując stanowisko od ' + item?.busyByName + ' poinformuj go o tym i sprawdź poprawność stanowiska przed przejęciem'}
                    rejectButton={"Anuluj"}
                    acceptButton={"Przejmuję"}
                    isOpen={takeOverStationDialogOpen}
                    handleAccept={handleTakeOverStationDialogOnAccept}
                    handleReject={handleTakeOverStationDialogOnReject}
                />
            </Grid>

        </Card>
    );
};

export default StationListCard;