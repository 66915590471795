import {
    Box, Typography, CircularProgress, Alert, Grid, Button,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from "react-router-dom";
import { getTaskDecision, postTaskDecision } from '../../services/task'

export default function Decision({ user }) {
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [success, setSuccess] = useState();

    const navigate = useNavigate();

    const [id, setId] = useState();
    const [title, setTitle] = useState();
    const [subTitle, setSubTitle] = useState();
    const [next, setNext] = useState();
    const [nextIsDisabled, setNextIsDisabled] = useState();
    const [nextButton, setNextButton] = useState();
    const [nextHelper, setNextHelper] = useState();
    const [refuse, setRefuse] = useState();
    const [refuseIsDisabled, setRefuseIsDisabled] = useState();
    const [refuseButton, setRefuseButton] = useState();
    const [refuseHelper, setRefuseHelper] = useState();
    const { taskId } = useParams()

    useEffect(() => {
        // console.log('TaskEdit-user', user)
        // console.log('TaskEdit-itemId', taskId)
        if (taskId) {
            loadDecisionData(user, taskId)
        } else {
            setIsLoading(false);
        }
    }, []);

    const loadDecisionData = async (user, taskId) => {
        setIsLoading(true);
        await getTaskDecision(user, taskId)
            .then(response => {
                // console.log(response)
                if (response.status == 'success') {
                    setId(response.result.id)
                    setTitle(response.result.title)
                    setSubTitle(response.result.subTitle)
                    setNext(response.result.next)
                    setNextIsDisabled(response.result.nextIsDisabled)
                    setNextButton(response.result.next ? response.result.nextButton : false)
                    setNextHelper(response.result.next ? response.result.nextHelper : '')
                    setRefuse(response.result.refuse)
                    setRefuseIsDisabled(response.result.refuseIsDisabled)
                    setRefuseButton(response.result.refuse ? response.result.refuseButton : false)
                    setRefuseHelper(response.result.refuse ? response.result.refuseHelper : '')
                } else {
                    setErrorMessage('błąd pobierania danych...');
                }
            })
            .catch(error => {
                setErrorMessage(error.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    const handleAccept = async e => {
        e.preventDefault();


        const newPayload = {
            user: user,
            decision: next
        }
        // console.log('Accept', taskId, newPayload)

        setIsSaving(true);
        let response
        try {
            response = await postTaskDecision(user, taskId, newPayload)
        } catch (error) {
            setErrorMessage(error.message);
            setSuccess(false)
        }
        setIsSaving(false);
        // console.log(response)
        if (!response.result.status) {
            setErrorMessage(response.result.message)
            setSuccess(false)
            throw new Error(response.result.message);
        } else {
            setSuccess(response.result.message)
            await new Promise(resolve => setTimeout(resolve, 2000));
            navigate("/tasks")
        }
    }

    const handleReject = async e => {
        e.preventDefault();

        const newPayload = {
            user: user,
            decision: refuse
        }
        // console.log('Refuse', taskId, newPayload)

        setIsSaving(true);
        let response
        try {
            response = await postTaskDecision(user, taskId, newPayload)
        } catch (error) {
            setErrorMessage(error.message);
            setSuccess(false)
        }
        setIsSaving(false);
        // console.log(response)
        if (!response.result.status) {
            setErrorMessage(response.result.message)
            setSuccess(false)
            throw new Error(response.result.message);
        } else {
            setSuccess(response.result.message)
            await new Promise(resolve => setTimeout(resolve, 2000));
            navigate("/tasks")
        }
    }


    return (
        <Grid container spacing={1} sx={{ justifyContent: 'center', alignContent: 'flex-start' }}>
            <Grid item container sx={{ width: '100%', height: "5vw", justifyContent: 'center', alignItems: 'center' }}>
                {isLoading && <CircularProgress sx={{ ml: 2 }} />}
                <Box sx={{ display: "flex", width: '90%', flexGrow: 1, mt: 10, justifyContent: 'center', alignItems: 'center' }}  >
                    {title && (<Typography align="center" variant="subtitle2">
                        Przejdź przez kolejne zakładki aby podjąć poniższą decyzję.
                    </Typography>)
                    }
                </Box>
                <Box sx={{ display: "flex", width: '90%', flexGrow: 1, mt: 10, justifyContent: 'center', alignItems: 'center' }}  >
                    {title && (<Typography align="center" variant="h5">
                        {title}
                    </Typography>)
                    }
                </Box>
                <Box sx={{ display: "flex", width: '100%', flexGrow: 1, mt: 1, justifyContent: 'center', alignItems: 'center' }} >
                    {subTitle && (<Typography align="center" variant="subtitle2" sx={{ width: '70%', }}>
                        {subTitle}
                    </Typography>)
                    }
                </Box>

                <Grid container spacing={1} sx={{ m: 5, width: "100%", justifyContent: 'center', alignContent: 'flex-start' }}>
                    {refuseHelper && (
                        <Box sx={{ width: "45%" }} justifyContent="center" >
                            <Typography align="center" variant="subtitle2">
                                {refuseHelper}
                            </Typography>
                        </Box>
                    )}
                    {nextHelper && (
                        <Box sx={{ width: "45%" }} justifyContent="center" >
                            <Typography align="center" variant="subtitle2">
                                {nextHelper}
                            </Typography>
                        </Box>
                    )}                    
                </Grid>

                <Grid justifyContent="center" alignItems="center" container spacing={1} sx={{ mt: 5, width: "100%", }}>
                    {refuseButton && (
                        <Box sx={{ width: "45%", display: 'flex', justifyContent: 'center' }}>
                            <Button
                                disabled={refuseIsDisabled}
                                onClick={handleReject}
                                component={Link}
                                to="/"
                                variant="outlined"
                                sx={{ mr: 1 }}>
                                {refuseButton}
                            </Button>
                        </Box>
                    )}
                    {nextButton && (
                        <Box sx={{ width: "45%", display: 'flex', justifyContent: 'center' }}>
                            <Button
                                disabled={nextIsDisabled}
                                onClick={handleAccept}
                                type="submit"
                                to="/"
                                component={Link}
                                variant="contained"
                                sx={{ mr: 1 }}>
                                {nextButton}
                            </Button>
                        </Box>
                    )}
                    {isSaving && <CircularProgress sx={{ ml: 2 }} />}
                    {errorMessage && <div><Alert sx={{ mt: 1 }} severity="error">{errorMessage}</Alert></div>}
                    {success && <div><Alert sx={{ mt: 1 }} severity="success">{success}</Alert></div>}
                </Grid>

            </Grid>
        </Grid>
    );
}