import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from "react-router-dom";
import theme from '../../theme';
import {
    Card, CardContent, CardMedia, CardActions, CardActionArea,
    Typography, Avatar, Chip, Box, Divider,
    Grid, Button, ListItem, Alert, CircularProgress,
    IconButton,
} from '@mui/material';

import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import HelpIcon from '@mui/icons-material/Help';

import { notifyMarkAsRead } from '../../services/notify'

import Label from '../Label';

const NotifyListCard = ({ element, user, refreshAction, ctrl }) => {

    const [isLoading, setIsLoading] = useState();
    const [isActionLoading, setActionIsLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState();

    const navigate = useNavigate();
    const handleOpen = async () => {

        if (!element.isRead) {
            setIsLoading(true);
            await notifyMarkAsRead(user, element.uid)
                .then(response => {
                    if (response.status !== 'success') {
                        setErrorMessage(response.message)
                        throw new Error(response.message);
                    } else {
                        navigate("../" + element.actionUrl, { replace: true })
                    }
                })
                .catch(error => {
                    setErrorMessage(error.message);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        } else {
            navigate("../" + element.actionUrl, { replace: true })
        }

    }

    const switchRead = async () => {
        if (!element.isRead) {
            setIsLoading(true);
            await notifyMarkAsRead(user, element.uid)
                .then(response => {
                    if (response.status !== 'success') {
                        setErrorMessage(response.message)
                        throw new Error(response.message);
                    }
                })
                .catch(error => {
                    setErrorMessage(error.message);
                })
                .finally(() => {
                    setIsLoading(false)
                    refreshAction()
                });
        }
    }

    return (
        <Card sx={{ maxWidth: '900px', minWidth: '600px', width: '90%', display: 'flex', margin: theme.spacing(0.5), backgroundColor: 'white' }}>
            <Grid container sx={{ margin: theme.spacing(0.5), width: '6%', justifyContent: 'center', alignItems: 'center' }}>
                <IconButton
                    sx={{ fontSize: 40 }}
                    aria-label="switch read"
                    aria-controls="notify"
                    onClick={switchRead}
                    color="white"
                >
                    {
                        element.isRead == true
                            ? <NotificationsNoneIcon fontSize="large" color="primary" />
                            : <NotificationsActiveIcon fontSize="large" color="primary" />
                    }
                </IconButton>
            </Grid>

            <Grid container sx={{ margin: theme.spacing(0.1), width: '80%', justifyContent: 'left', alignItems: 'left' }}>
                <Typography align={"left"} color={"black"} variant="h6">
                    {element?.carName}
                </Typography>
                {/* <Chip size="small" color="primary" label="id:2" /> */}
                <Typography align={"left"} color={"black"} variant="subtitle2">
                    {element?.details}
                </Typography>
                <Divider />
                <Box sx={{ display: "flex", alignItems: "baseline", flexGrow: 1 }} >
                    <Label data={{ label: "", value: element?.hid }} />
                    <Label data={{ label: ", zlecający:", value: element?.customerName }} />
                    <Label data={{ label: ", operator:", value: element?.user }} />
                    <Label data={{ label: ", data:", value: element?.date }} />
                </Box>
            </Grid>

            <Grid container sx={{ margin: theme.spacing(0.1), width: '10%', justifyContent: 'center', alignItems: 'center' }}>
                <Button disabled={false} onClick={handleOpen} variant='outlined'>
                    Otwórz
                </Button>
            </Grid>
            {isLoading && <CircularProgress sx={{ ml: 2 }} />}
            {errorMessage && <div><Alert severity="error">{errorMessage || 'Nieznany błąd'}</Alert></div>}
        </Card>
    );
};

export default NotifyListCard;