import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Alert,
  CircularProgress,
  Button,
  CssBaseline,
  TextField,
  FormControl, FormGroup, FormControlLabel, Switch,
  Fade,
  Grid,
  Box,
  Typography,
  Container,
} from '@mui/material';
import {
  fornameValidator,
  lastnameValidator,
  phoneValidator,
  doesFormHasErrors,
  passwordValidator
} from '../validators/ProfileValidator'
import { setPassword } from '../services/profile'

import ReCAPTCHA from 'react-google-recaptcha';
const SITE_KEY = process.env.REACT_APP_SITE_KEY;

export default function Password({ user, setUser }) {
  const navigate = useNavigate();
  const recaptchaRef = React.createRef();

  
  // statusy ładowania i zapisu
  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [success, setSuccess] = useState();
  // dane
  const [userId, setUserId] = useState(user.id);
  const [login, setEmail] = useState(user.login);

  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');

  // sterowanie ekranem
  const [isFormRO, setIsFormRO] = useState(false);
  const [isValid, setIsValid] = useState(true)

  useEffect(() => {
    // console.log('TaskEdit-user', user)
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    const captchaToken = await recaptchaRef.current.executeAsync()
    
    const newPayload = {
      newPassword1,
      newPassword2,
      captcha: captchaToken
    }

    // walidacja
    const thereAreErrors = passwordValidator(newPayload,false)
    console.log(thereAreErrors)
    setIsValid(!thereAreErrors)

    if (!thereAreErrors) {

      setIsLoading(true);
      await setPassword(user, newPayload)
        .then(response => {
          if (response.status !== 'success') {
            console.log(response)
            setErrorMessage(response.message)
            setSuccess(false)
            throw new Error(response.message);
          } else {
            // reload list
            // setUser(user)
            navigate("/profile")
          }
        })
        .catch(error => {
          setErrorMessage(error.message);
          setSuccess(false)
        })
        .finally(() => {
          setIsLoading(false);
        });

    } else {
      setErrorMessage(thereAreErrors)
    }
    return
  }

  const onChange = async e => {
  }

  return (
    <Box sx={{ ml: 1, display: "grid", justifyContent: 'center' }}>
      <Grid container spacing={1} sx={{ maxWidth: "960px", justifyContent: 'center', alignContent: 'flex-start' }}>

        {/* // tytuł */}
        <Box sx={{ display: "flex", flexGrow: 1, mt: 5 }} >
          <Typography sx={{ m: 1 }}>
            Zmiana hasła
          </Typography>
        </Box>

        {/* // pola */}
        <Grid item container sx={{ justifyContent: 'space-between' }} >
          <Box
            sx={{ mr: 1, minWidth: "100%" }}>

            <TextField
              margin="normal"
              required
              fullWidth
              name="password1"
              label="Nowe hasło"
              type="password"
              // type='text'
              autoComplete='new-password'
              id="password1"
              value={newPassword1 || ''}
              // helperText="Wprowadź nowe hasło"
              onChange={(e) => setNewPassword1(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password1"
              label="Powtórz nowe hasło"
              type="password"
              id="password2"
              autoComplete='new-password'
              value={newPassword2 || ''}
              // helperText="Powtórz nowe hasło"
              onChange={(e) => setNewPassword2(e.target.value)}
            />
          </Box>

        </Grid>
        {/* // akcje */}
        <Grid item container sx={{ mt: 5, justifyContent: 'space-between', minWidth: "100%" }} >
          <Box
            sx={{ mr: 1, display: "flex", justifyContent: 'right', alignContent: 'flex-end' }}>

            <Button component={Link} to="/profile" variant="outlined" sx={{ mr: 1 }}>
              Anuluj
            </Button>

            <Button
              disabled={isFormRO}
              onClick={handleSubmit}
              type="submit"
              variant="contained"
              sx={{ mr: 1 }}>
              Zapisz
            </Button>
          </Box>
        </Grid>
        <Fade
          in={!isValid} //Write the needed condition here to make it appear
          timeout={{ enter: 1000, exit: 1000 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
          addEndListener={() => {
            setTimeout(() => {
              setIsValid(true)
            }, 2000);
          }}
        >
          <Alert severity="error" variant="standard" className="alert">
            Występują błędy walidacji
          </Alert>
        </Fade>
        {isLoading && <CircularProgress sx={{ ml: 2 }} />}
        {errorMessage && <div><Alert severity="error">{errorMessage}</Alert></div>}
        {success && <div><Alert severity="success">{success}</Alert></div>}

      </Grid>
      <Grid>
        <ReCAPTCHA
          className="recaptcha"
          size="invisible"
          data-theme="dark"
          render="explicit"
          sitekey={SITE_KEY}
          ref={recaptchaRef}
          onChange={onChange}
        />
      </Grid>
    </Box>
  );
}