import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {
  Alert,
  CircularProgress,
  Button,
  CssBaseline,
  TextField,
  FormControl, FormGroup, FormControlLabel, Switch,
  Fade,
  Grid,
  Link,
  Box,
  Typography,
  Container,
} from '@mui/material';

import Image from '../assets/soft99-garage.png';
import Logo from '../assets/logo_garage.svg';

export default function Register() {
  const navigate = useNavigate();

  // statusy ładowania i zapisu
  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [success, setSuccess] = useState();

  // sterowanie ekranem
  const [isFormRO, setIsFormRO] = useState(false);
  const [isValid, setIsValid] = useState(true)

  useEffect(() => {
    // console.log('TaskEdit-user', user)
  }, []);

  const handleSubmit = async e => {
    return
  }

  const onChange = async e => {
  }

  return (
    <Grid container spacing={1} sx={{ width: "100vw", height: "100vh" }}>
      <Grid item xs={12} sm={8} md={8} sx={{ height: '-webkit-fill-available' }}>
        <img src={Image} alt="budynek soft99 Garage nocą" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </Grid>
      <Grid item container sx={{ justifyContent: 'center', alignItems: 'center' }} xs={12} sm={4} md={4} >
        <Box sx={{ width: '60%' }} >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={Logo} alt="logo soft99 Garage" width={'90%'} />
          </Box>
          <Typography component="h1" variant="overline">
            PIA
          </Typography>
          <Typography component="h1" variant="h5" sx={{ mt: 5 }}>
            Rejestracja
          </Typography>
          <Grid container>
              <Grid item xs>

                <Typography component="h1" variant="subtitle1" sx={{ mb: 1, mt: 5 }}>
                  Aby otrzymać dostęp do serwisu skontaktuj się z soft99Garage.
                </Typography>

                <Link href="/" variant="body2">
                  Strona logowania
                </Link>
              </Grid>
            </Grid>

        </Box>
      </Grid >
    </Grid >
  );
}