import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  CircularProgress,
  Button,
  CssBaseline,
  TextField,
  FormControl, FormGroup, FormControlLabel, Switch,
  Fade,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from '@mui/material';
import {
  emailValidator
} from '../validators/ProfileValidator'
import { passwordReset } from '../services/passwordReset'

import ReCAPTCHA from 'react-google-recaptcha';
const SITE_KEY = process.env.REACT_APP_SITE_KEY;

import Image from '../assets/soft99-garage.png';
import Logo from '../assets/logo_garage.svg';

export default function LostPassword() {
  const navigate = useNavigate();
  const recaptchaRef = React.createRef();

  // statusy ładowania i zapisu
  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [success, setSuccess] = useState();
  // dane
  const [email, setEmail] = useState();

  // sterowanie ekranem
  const [isFormRO, setIsFormRO] = useState(false);
  const [isValid, setIsValid] = useState(true)

  useEffect(() => {
    // console.log('TaskEdit-user', user)
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    const captchaToken = await recaptchaRef.current.executeAsync()

    const newPayload = {
      email,
      captcha: captchaToken
    }

    // walidacja
    const thereAreErrors = emailValidator(newPayload, false)
    // console.log(thereAreErrors)
    setIsValid(!thereAreErrors)

    if (!thereAreErrors) {

      setIsLoading(true);
      await passwordReset(newPayload)
        .then(response => {
          if (response.status !== 'success') {
            console.log(response)
            setErrorMessage(response.message)
            setSuccess(false)
            throw new Error(response.message);
          } else {
            // reload list
            // setUser(user)
            setSuccess(true)
          }
        })
        .catch(error => {
          setErrorMessage(error.message);
          setSuccess(false)
        })
        .finally(() => {
          setIsLoading(false);
        });

    } else {
      setIsValid(false)
      setErrorMessage(thereAreErrors)
    }
    return
  }

  const onChange = async e => {
  }

  return (
    <Grid container spacing={1} sx={{ width: "100vw", height: "100vh" }}>
      <Grid item xs={12} sm={8} md={8} sx={{ height: '-webkit-fill-available' }}>
        <img src={Image} alt="budynek soft99 Garage nocą" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </Grid>
      <Grid item container sx={{ justifyContent: 'center', alignItems: 'center' }} xs={12} sm={4} md={4} >
        <Box sx={{ width: '60%' }} >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={Logo} alt="logo soft99 Garage" width={'90%'} />
          </Box>
          <Typography component="h1" variant="overline">
            PIA
          </Typography>
          <Typography component="h1" variant="h5" sx={{ mt: 5 }}>
            Przypomnienie hasła
          </Typography>

          {!success && (
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, }}>

              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                error={!isValid}
                autoFocus
                value={email || ''}
                onChange={(e) => setEmail(e.target.value)}
                helperText="Wprowadź swój email"
              />

              <Button
                disabled={isFormRO}
                onClick={handleSubmit}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Wyślij
              </Button>

              {isLoading && <CircularProgress sx={{ ml: 2 }} />}
              {errorMessage && <div><Alert severity="error">{errorMessage}</Alert></div>}
              {success && <div><Alert severity="success">{success}</Alert></div>}

              <Grid container>
                <Grid item xs>
                  <Link href="/" variant="body2">
                    Strona logowania
                  </Link>
                </Grid>
              </Grid>

              <Grid>
                <ReCAPTCHA
                  className="recaptcha"
                  size="invisible"
                  data-theme="dark"
                  render="explicit"
                  sitekey={SITE_KEY}
                  ref={recaptchaRef}
                  onChange={onChange}
                />
              </Grid>
            </Box>
          )}

          {success && (
            <Grid container>
              <Grid item xs>

                <Typography component="h1" variant="subtitle1" sx={{ mb: 1, mt: 5 }}>
                  Wysłaliśmy na Twój email link do resetowania hasła. Sprawdź swoją pocztę.
                </Typography>

                <Link href="/" variant="body2">
                  Strona logowania
                </Link>
              </Grid>
            </Grid>
          )}

        </Box>
      </Grid >
    </Grid >
  );
}