export async function getNotificationCount(user) {
    const url = process.env.REACT_APP_API_URL + '/notify/count'
    // console.log(`loadTasks: ${url}`)
    // console.log(user.token)
    let result
    try {
        result = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
    } catch (err) {
        console.error(err)
        return {
            status: 'error',
            message: 'Błąd techniczny'
        }
    }

    switch (result.status) {
        case 200:
            return {
                status: 'success',
                result: await result.json()
            }
        case 500:
            return {
                status: 'error',
                message: 'Błąd działania aplikacji spróbuj ponownie później'
            }
        case 403:
            return {
                status: 'credential',
                message: 'Błędny użytkownik lub hasło'
            }
        case 401:
            return {
                status: 'credential',
                message: 'Sesja wygasła'
            }
        default:
            return {
                status: 'unknown',
                message: 'Nieznany błąd'
            }
    }
}

export async function getNotification(user, actionFilter, pageNumber, rowsPerPage) {
    const url = process.env.REACT_APP_API_URL + `/notify?pageNumber=${pageNumber}&rowsPerPage=${rowsPerPage}&actionFilter=${actionFilter}`
    // console.log(`loadTasks: ${url}`)
    // console.log(user.token)
    let result
    try {
        result = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
    } catch (err) {
        console.error(err)
        return {
            status: 'error',
            message: 'Błąd techniczny'
        }
    }

    switch (result.status) {
        case 200:
            return {
                status: 'success',
                result: await result.json()
            }
        case 500:
            return {
                status: 'error',
                message: 'Błąd działania aplikacji spróbuj ponownie później'
            }
        case 403:
            return {
                status: 'credential',
                message: 'Błędny użytkownik lub hasło'
            }
        case 401:
            return {
                status: 'credential',
                message: 'Sesja wygasła'
            }
        default:
            return {
                status: 'unknown',
                message: 'Nieznany błąd'
            }
    }
}

export async function notifyMarkAsRead(user, uid) {
    const url = process.env.REACT_APP_API_URL + `/notify/${uid}`
    // console.log(`postTask: ${url}`)
    let result
    try {
        result = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body: JSON.stringify({ 'uid': uid }) 
        })
    } catch (err) {
        console.error(err)
        return {
            status: 'error',
            message: 'Błąd techniczny'
        }
    }
  
    switch (result.status) {
        case 200:
            return {
                status: 'success',
                result: await result.json()
            }
        case 500:
            return {
                status: 'error',
                message: 'Błąd działania aplikacji spróbuj ponownie później'
            }
        case 403:
            return {
                status: 'credential',
                message: 'Błędny użytkownik lub hasło'
            }
        case 401:
            return {
                status: 'credential',
                message: 'Sesja wygasła'
            }
        default:
            return {
                status: 'unknown',
                message: 'Nieznany błąd'
            }
    }
  }