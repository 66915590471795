
export async function passwordReset(payload) {
  // console.log(JSON.stringify(payload))
  let result
  try {
    result = await fetch(`${process.env.REACT_APP_API_URL}/passwordreset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
  } catch (err) {
    console.error(err)
    return {
      status: 'error',
      message: 'Błąd techniczny'
    }
  }

  switch (result.status) {
    case 500:
      return {
        status: 'error',
        message: 'Błąd działania aplikacji spróbuj ponownie później'
      }
    case 404:
      return {
        status: 'error',
        message: 'Błąd działania aplikacji spróbuj ponownie później'
      }
    case 403:
      return await result.json()
    case 200:
      return await result.json()
    default:
      return {
        status: 'error',
        message: 'Błąd działania aplikacji spróbuj ponownie później'
      }
  }
}

export async function passwordResetVerify(payload) {
  // console.log(JSON.stringify(payload))
  let result
  try {
    result = await fetch(`${process.env.REACT_APP_API_URL}/passwordresetverify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
  } catch (err) {
    console.error(err)
    return {
      status: 'error',
      message: 'Błąd techniczny'
    }
  }

  switch (result.status) {
    case 500:
      return {
        status: 'error',
        message: 'Błąd działania aplikacji spróbuj ponownie później'
      }
    case 404:
      return {
        status: 'error',
        message: 'Błąd działania aplikacji spróbuj ponownie później'
      }
    case 403:
      return await result.json()
    case 200:
      return await result.json()
    default:
      return {
        status: 'error',
        message: 'Błąd działania aplikacji spróbuj ponownie później'
      }
  }
}

export async function passwordSet(payload) {
  // console.log(JSON.stringify(payload))
  let result
  try {
    result = await fetch(`${process.env.REACT_APP_API_URL}/passwordset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload)
    })
  } catch (err) {
    console.error(err)
    return {
      status: 'error',
      message: 'Błąd techniczny'
    }
  }

  switch (result.status) {
    case 500:
      return {
        status: 'error',
        message: 'Błąd działania aplikacji spróbuj ponownie później'
      }
    case 404:
      return {
        status: 'error',
        message: 'Błąd działania aplikacji spróbuj ponownie później'
      }
    case 403:
      return {
        status: 'noaccount',
        message: await result.json()
      }
    case 200:
      return {
        status: 'success',
        message: await result.json()
      }
    default:
      return {
        status: 'error',
        message: 'Błąd działania aplikacji spróbuj ponownie później'
      }
  }
}