export async function loginByToken(token) {
  let result
  // console.log(`loginByToken: ${token}`)
  try {
    result = await fetch(`${process.env.REACT_APP_API_URL}/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify({})
    })
  } catch (err) {
    console.error(err)
    return {
      status: 'error',
      message: 'Błąd techniczny'
    }
  }

  switch (result.status) {
    case 500:
      return {
        status: 'error',
        message: 'Błąd działania aplikacji spróbuj ponownie później'
      }
    case 403:
      return {
        status: 'credential',
        message: 'Błędny użytkownik lub hasło'
      }
    default:
      return {
        status: 'success',
        response: await result.json()
      }
  }
}
export async function loginUser(credentials) {
  // console.log(credentials)
    let result
    try {
      result = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
      })
    } catch (err) {
      console.error(err)
      return {
        status: 'error',
        message: 'Błąd techniczny'
      }
    }
  
    switch (result.status) {
      case 500:
        return {
          status: 'error',
          message: 'Błąd działania aplikacji spróbuj ponownie później'
        }
      case 403:
        return {
          status: 'credential',
          message: 'Błędny użytkownik lub hasło'
        }
      default:
        return {
          status: 'success',
          response: await result.json()
        }
    }
  }

