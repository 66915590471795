import React, { useState, useEffect } from 'react';
import theme from '../../theme';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  Card, CardContent, Accordion, AccordionSummary, AccordionDetails,
  Box, Radio,
  Button, Typography, TextField, Grid,
  CircularProgress, Alert,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getServices } from '../../services/services';

const SelectService = ({ user, defaultSelectedService, isOpen, handleAcceptService, handleRejectService, carModel }) => {

  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [pageCount, setPageCount] = useState(0);

  const [servicesList, setServicesList] = useState();
  const [filterService, setFilterService] = useState()
  const [filteredServicesList, setFilteredServicesList] = useState();

  const [serviceSelected, setService] = useState(defaultSelectedService || 1);
  const [serviceSelectedName, setServiceName] = useState(defaultSelectedService || 1);
  const [serviceSelectedValue, setServiceValue] = useState(defaultSelectedService || 1);

  const filterServiceByText = async (list,filter) => {
    const filterX = filter.toLowerCase()
    // console.log(`filter: ${filterX}, list.length: ${list.length}`)
    let results = []
    if (list)
    list.forEach(element => {
        // console.log(`element: ${element.title}, ${element.subtitle}`)
        if (
          element?.title.toLowerCase().indexOf(filterX) > -1
          || element?.subtitle.toLowerCase().indexOf(filterX) > -1
          || element?.description.toLowerCase().indexOf(filterX) > -1
          || element?.id == filterX
          || element?.value == filterX
        ) {
          results.push(element)
        }
      })
    // console.log(`results.length: ${results.length}`)
    setFilteredServicesList(results)
  }
  const onSearchServiceChanged = async (event) => {
    const filter = event.target.value.toLowerCase()
    setFilterService(event.target.value)
    if (!filter) {
      setFilteredServicesList(servicesList)
    } else {
      filterServiceByText(servicesList,filter)
    }
  }

  const loadServicesAndSetFirst = async (user, pageNumber, pageSize) => {
    setIsLoading(true);
    await getServices(user, pageNumber, pageSize)
      .then(response => {
        if (response.status !== 'success') {
          setErrorMessage(response.message)
          throw new Error(response.message);
        }
        const servicesListResult = response.result.services
        // console.log(servicesListResult)
        setServicesList(servicesListResult)
        filterServiceByText(servicesListResult,carModel)
        // setFilteredServicesList(servicesListResult)
        setPageNumber(response.result.pageNumber);
        setPageCount(response.result.pageCount)
        setPageSize(response.result.pageSize)
        // wyznaczenie pierwszego elementu jako domyślnego
        let serviceId = defaultSelectedService
        if (!serviceId) {
          serviceId = servicesListResult[0].id
          setService(serviceId)
        }
        // console.log('start - ', defaultSelectedService)
        const x = servicesListResult.filter(item => item['id'] === serviceId)[0]
        setServiceName(x.title)
        setServiceValue(x.value)
      })
      .catch(error => {
        setErrorMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    // console.log(`model=${carModel}`)
    setFilterService(carModel)

    if (!servicesList || servicesList.length == 0) {
      loadServicesAndSetFirst(user, pageNumber, pageSize)
    }
  }, []);


  const handleChangeSelection = (serviceId) => {
    // console.log(`serviceId: ${serviceId}`)
    setService(serviceId)
    const x = servicesList.filter(item => item['id'] === serviceId)[0]
    // console.log(x)
    setServiceName(x.title)
    setServiceValue(x.value)
  };

  const printTasks = (tasks) => {
    return (
      <>
        {(tasks && tasks.length > 0) ?
          tasks.map((task) => (
            <Typography variant="caption" display="block" >
              {"- " + task.name}
            </Typography>
          )
          ) :
          ""
        }
      </>
    )
  }

  const serviceCard = (serviceItem) => {
    return (
      <>
        <Card key={serviceItem?.id} sx={{ display: 'flex', margin: theme.spacing(1), backgroundColor: 'white' }}>
          <CardContent>
            <Box sx={{ display: "flex", alignItems: "center", }}>
              <Box sx={{ display: "flex", flexGrow: 1 }}>
                <Typography sx={{ textTransform: 'uppercase' }} color={'black'} variant="h5" component="div">
                  {serviceItem?.title}
                </Typography>
              </Box>
              <Radio
                checked={serviceSelected === serviceItem.id}
                onChange={() => handleChangeSelection(serviceItem?.id)}
                name={"id-" + serviceItem?.id}
                sx={{ color: 'black', '&.Mui-checked': { color: 'black', }, }} />
            </Box>
            <Typography sx={{ textTransform: 'uppercase' }} color={'black'} variant="subtitle1">
              {serviceItem?.subtitle}
            </Typography>
            <Typography color={'black'} variant="body2">
              {serviceItem?.description}
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }} >
              <Typography color={'black'} gutterBottom variant="button" component="div">
                {serviceItem?.value} zł
              </Typography>
            </Box>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Zobacz zakres prac</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {(serviceItem.steps && serviceItem.steps.length > 0) ?
                  serviceItem.steps.map((step) => (
                    <Typography variant="body1" paragraph={true} key={step.number}>
                      {step.number + ". " + step.desc}
                      {printTasks(step.tasks)}
                    </Typography>
                  ))
                  :
                  <Typography variant="body1">
                    Nie zdefiniowano zakresu prac
                  </Typography>
                }
              </AccordionDetails>
            </Accordion>
          </CardContent>
        </Card>
      </>
    )
  }

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleRejectService}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Grid container justifyContent="center" alignItems="center">
          <DialogTitle id="alert-dialog-title"> Wybierz pakiet</DialogTitle>
          <Typography variant="subtitle1">Wyszukaj</Typography>
          <TextField
            sx={{ ml: 1, minWidth: "32%" }}
            // margin="dense"  
            inputProps={{ "data-testid": "searchService" }}
            id="searchService"
            label="Wpisz 2 znaki"
            // placeholder='np. Jan Kowalski'
            name="searchService"
            autoFocus
            defaultValue={carModel}
            // disabled={clientMode !== 'cNew'}
            value={filterService || ''}
            onChange={onSearchServiceChanged}
          />
        </Grid>
        {isLoading && <CircularProgress sx={{ ml: 2 }} />}
        {errorMessage && <div><Alert severity="error">{errorMessage}</Alert></div>}
        <DialogContent>
          {(filteredServicesList && filteredServicesList.length > 0) ?
            filteredServicesList.map((serviceItem) => (serviceCard(serviceItem))
            ) :
            !isLoading && !errorMessage && <Typography variant="subtitle2">
              Nie ma pakietów dla wybranych kryteriów. Jeżeli uważasz, że to błąd - skontaktuj się z Soft99
            </Typography>
          }
        </DialogContent>
        <DialogActions>
          {
            defaultSelectedService !== serviceSelected ? (
              <Button onClick={handleRejectService} autoFocus>
                Anuluj
              </Button>
            ) : ""
          }
          {!isLoading && !errorMessage && <Button onClick={() => handleAcceptService(serviceSelected, serviceSelectedName, serviceSelectedValue)}>Zapisz</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectService;