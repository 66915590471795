import React from 'react';
import theme from '../theme';
import { Typography } from '@mui/material';

const Label = ({ data }) => {

    return (
        <>
            <Typography sx={{ml:theme.spacing(0.1), mr:theme.spacing(0.1)}} color={theme.palette.bar.main} variant="caption">
                {data?.label}
            </Typography>
            <Typography sx={{ml:theme.spacing(0.1), mr:theme.spacing(0.1)}} color={"black"} variant="subtitle2">
                {data?.value}
            </Typography>
        </>
    );
};

export default Label;