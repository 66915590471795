/** oczekiwana struktura
   const servicesList = [
    {
      id: 1,
      title: "Pakiet 1",
      subtitle: "Ceramiczna ochona lakieru 2-lata",
      description: "Podstawowa usługa detailingu oparta o JEDNOETAPOWĄ korektę lakieru, która w krótkim czasie, pozwala usunąć ok. 60-70% defektów - rys, oksydacji i hologramów. Lakier zabezpieczany jest klasycznym WOSKIEM, który doskonale podkreśla blask i chroni przed czynnikami atmosferycznymi. Pakiet PLUS rekomendowany jest dla klientów oczekujących skutecznego i szybkiego odświeżenia lakieru auta.",
      value: 1500,
      steps: [
        {
          number: 1,
          desc: "Przyjęcie samochodu",
          tasks: [
            { name: "protokół przyjęcia samochodu, wstępna inspekcja lakieru, wybór wykonywanej usługi" }
          ]
        },
        {
          number: 2,
          desc: "Proces Dekontaminacji",
          tasks: [
            { name: "przygotowanie samochodu ( powłoki lakierniczej oraz szyb, felg ) do dalszych prac" }
          ]
        },
        {
          number: 3,
          desc: "Korekta lakieru",
          tasks: [
            { name: "polerowanie jednoetapowe" },
            { name: "usuwanie zarysowań z powłoki lakierniczej" }
          ]
        },
        {
          number: 4,
          desc: "Przygotowanie do aplikacji powłok kwarcowych",
          tasks: [
            { name: "finalne przygotowanie do aplikacji powłok" },
            { name: "ponowne mycie, odtłuszczanie" }
          ]
        },
        {
          number: 5,
          desc: "Aplikacji powłoki kwarcowej G’zox Real Glass Coat na lakier",
          tasks: [
          ]
        },
        {
          number: 6,
          desc: "Proces utwardzania powłoki kwarcowej",
          tasks: [
          ]
        },
        {
          number: 7,
          desc: "Zabezpieczenie szyb powłoką kwarcową",
          tasks: [
            { name: "powłoka hydrofobowa" },
            { name: "dekontaminacja szyb" },
            { name: "odtłuszczanie powierzchni" },
            { name: "aplikacja powłoki kwarcowej hydrofobowej" },
            { name: "proces utwardzania powłoki kwarcowej" },
          ]
        },
        {
          number: 8,
          desc: "Proces inspekcji końcowej",
          tasks: [
            { name: " inspekcja po wykonanej usłudze," },
          ]
        },
      ]
    },

  ]
 */


export async function getServices(user, pageNumber, pageSize) {
    const url = process.env.REACT_APP_API_URL+'/services'
    let result
    try {
        result = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
    } catch (err) {
        console.error(err)
        return {
            status: 'error',
            message: 'Błąd techniczny'
        }
    }

    switch (result.status) {
        case 200:
            return {
                status: 'success',
                result: await result.json()
            }        
        case 500:             
            return {
                status: 'error',
                message: 'Błąd działania aplikacji spróbuj ponownie później'
            }
        case 403:
            return {
                status: 'credential',
                message: 'Błędny użytkownik lub hasło'
            }
        default:
            return {
                status: 'unknown',
                message: 'Nieznany błąd'
            }
    }
}
