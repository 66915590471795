// import * as React from 'react';
import React from "react";
import {createRoot} from 'react-dom/client';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';

import { BrowserRouter } from 'react-router-dom';
import RouteManager from './routeManager';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
root.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <BrowserRouter>
      <CssBaseline />
        <RouteManager />
    </BrowserRouter>
  </ThemeProvider>
);
