import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Alert,
  CircularProgress,
  Button,
  CssBaseline,
  TextField,
  FormControl, FormGroup, FormControlLabel, Switch,
  Fade,
  Grid,
  Box,
  Typography,
  Container,
} from '@mui/material';

import { getReport1 } from '../services/report'
var FileSaver = require('file-saver');


export default function Reports({ user }) {
  const navigate = useNavigate();
  // statusy ładowania i zapisu
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [success, setSuccess] = useState();

  // sterowanie ekranem
  const [isFormRO, setIsFormRO] = useState(false);
  const [isValid, setIsValid] = useState(true)

  useEffect(() => {

  }, []);


  const handleDownload1 = async e => {
    e.preventDefault();

    setIsLoading(true);
    await getReport1(user, { dateFrom: '2024-06-01', dateTo: '' })
      .then(resp => {
        return FileSaver.saveAs(resp, "raport.xlsx");
      })
      .catch(error => {
        setErrorMessage(error.message);
        setSuccess(false)
      })
      .finally(() => {
        setIsLoading(false);
      });

    return
  }

  return (
    <Box sx={{ ml: 1, display: "grid", justifyContent: 'center' }}>
      <Grid container spacing={1} sx={{ maxWidth: "960px", justifyContent: 'center', alignContent: 'flex-start' }}>

        {/* // tytuł */}
        <Box sx={{ display: "flex", flexGrow: 1, mt: 5 }} >
          <Typography sx={{ m: 1 }}>
            Raporty
          </Typography>
        </Box>

        {/* // pola */}
        <Grid item container sx={{ justifyContent: 'space-between' }} >

          <Box
            sx={{ mr: 1, display: "flex", justifyContent: 'right', alignContent: 'flex-end' }}>
            <Button
              disabled={isFormRO}
              onClick={handleDownload1}
              type="submit"
              variant="contained"
              sx={{ mr: 1 }}>
              Wygeneruj raport
            </Button>
          </Box>

        </Grid>
        {/* // akcje */}
        <Grid item container sx={{ mt: 5, justifyContent: 'space-between', minWidth: "100%" }} >
          <Box
            sx={{ mr: 1, display: "flex", justifyContent: 'right', alignContent: 'flex-end' }}>
            <Button component={Link} to="/" variant="outlined" sx={{ mr: 1 }}>
              Wróć
            </Button>
          </Box>
        </Grid>
        <Fade
          in={!isValid} //Write the needed condition here to make it appear
          timeout={{ enter: 1000, exit: 1000 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
          addEndListener={() => {
            setTimeout(() => {
              setIsValid(true)
            }, 2000);
          }}
        >
          <Alert severity="error" variant="standard" className="alert">
            Występują błędy
          </Alert>
        </Fade>
        {isLoading && <CircularProgress sx={{ ml: 2 }} />}
        {errorMessage && <div><Alert severity="error">{errorMessage}</Alert></div>}
        {success && <div><Alert severity="success">{success}</Alert></div>}
      </Grid>
    </Box>
  );
}