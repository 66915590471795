export async function putUser(user, payload) {
  const url = process.env.REACT_APP_API_URL + `/profile`
  // console.log(`postTask: ${url}`)
  let result
  try {
      result = await fetch(url, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${user.token}`
          },
          body: JSON.stringify(payload)
      })
  } catch (err) {
      console.error(err)
      return {
          status: 'error',
          message: 'Błąd techniczny'
      }
  }

  switch (result.status) {
      case 200:
          return {
              status: 'success',
              result: await result.json()
          }
      case 500:
          return {
              status: 'error',
              message: 'Błąd działania aplikacji spróbuj ponownie później'
          }
      case 403:
          return {
              status: 'credential',
              message: 'Błędny użytkownik lub hasło'
          }
      case 401:
          return {
              status: 'credential',
              message: 'Sesja wygasła'
          }
      default:
          return {
              status: 'unknown',
              message: 'Nieznany błąd'
          }
  }
}

export async function setPassword(user, payload) {
  const url = process.env.REACT_APP_API_URL + `/profile/password`
  // console.log(`postTask: ${url}`)
  let result
  try {
      result = await fetch(url, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${user.token}`
          },
          body: JSON.stringify(payload)
      })
  } catch (err) {
      console.error(err)
      return {
          status: 'error',
          message: 'Błąd techniczny'
      }
  }

  switch (result.status) {
      case 200:
          return {
              status: 'success',
              result: await result.json()
          }
      case 500:
          return {
              status: 'error',
              message: 'Błąd działania aplikacji spróbuj ponownie później'
          }
      case 403:
          return {
              status: 'credential',
              message: 'Błędny użytkownik lub hasło'
          }
      case 401:
          return {
              status: 'credential',
              message: 'Sesja wygasła'
          }
      default:
          return {
              status: 'unknown',
              message: 'Nieznany błąd'
          }
  }
}