import React from 'react';
import theme from '../../theme';
import {
    Card, CardContent, Typography, Avatar, Chip, Box, Divider,
    Grid, Button, ListItem
} from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import Label from '../Label';
import { useNavigate } from "react-router-dom";
import moment from 'moment-timezone'

const TaskListCard = ({ item, user }) => {

    const renderStars = (progress) => {
        const stars = [];
        const maxStars = 5;

        for (let i = 0; i < maxStars; i++) {
            const starIcon = i < progress ? (
                <StarIcon key={i} sx={{ color: theme.palette.warning.main, mb: theme.spacing(0.5) }} />
            ) : (
                <StarIcon key={i} sx={{ color: 'gray', mb: theme.spacing(0.5) }} />
            );

            stars.push(starIcon);
        }

        return stars;
    };

    const navigate = useNavigate();
    const handleOpen= () => {
        // console.log('TaskListCard - user',user)
        // console.log('TaskListCard - itemId:',item)
        navigate("/opentask/"+item.uid, { params: {itemId:item.id, user:user }}) //itemId, user 
    }

    return (
        <Card sx={{ maxWidth: '900px', minWidth: '600px', width: '90%', display: 'flex', margin: theme.spacing(1), backgroundColor: 'white' }}>

            <Grid item container sx={{ width: '90%', height: "100%", justifyContent: 'center', alignItems: 'center' }}>
                <Grid item container sx={{ margin: theme.spacing(0.1), backgroundColor: 'white' }}>
                    <Box sx={{ display: "flex", alignItems: "baseline", flexGrow: 1 }} >
                        {/* <Chip label={""+item?.id} size="small" variant="outlined" color="primary" sx={{ margin: theme.spacing(0.2) }} /> */}
                        {/* <Label data={{ label: "id", value: item?.id }} /> */}
                        <Label data={{ label: "status", value: item?.status }} />
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "baseline" }} >
                        {/* <Label data={{ label: "wizyta", value: item?.dates }} /> */}
                        {item?.dates ? (<Chip label={item?.dates} size="small" color="primary" sx={{ margin: theme.spacing(0.2) }} />) : ''}
                    </Box>
                </Grid>
                <Grid item container sx={{ margin: theme.spacing(0.1), justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ display: "flex", alignItems: "baseline", flexGrow: 1 }} >
                        <Label data={{ label: "zlecający", value: item?.customerName }} />
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "baseline" }} >
                        <Label data={{ label: "samochód", value: item?.carName }} />
                    </Box>
                </Grid>
                <Grid item container sx={{ margin: theme.spacing(0.1), justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ display: "flex", alignItems: "baseline", flexGrow: 1 }} >
                        <Label data={{ label: "uwagi", value: item?.comments }} /> 
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "baseline" }} >
                        <Label data={{ label: "ostatnia zmiana", value: moment.utc(item?.updatedDate).tz('Europe/Warsaw').format('YYYY-MM-DD HH:mm:ss') }} />
                    </Box>
                </Grid>
                <Grid item container sx={{ margin: theme.spacing(0.1), justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ display: "flex", alignItems: "baseline", flexGrow: 1 }} >
                        <Chip label={"" + item?.id} variant="outlined" size="small" color="primary" sx={{ margin: theme.spacing(0.2) }} />
                        {item.chosenService ? (<Chip label={item.chosenService} size="small" color="primary" sx={{ margin: theme.spacing(0.2) }} />) : ''}
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "baseline" }} >
                        {renderStars(item.salesRating)}
                    </Box>
                </Grid>
            </Grid>
            <Grid item container sx={{ margin: theme.spacing(2), width: '10%', justifyContent: 'center', alignItems: 'center' }}>
                <Button type="submit" onClick={handleOpen} variant="contained" sx={{ minWidth: "68px" }}>
                    Otwórz
                </Button>
            </Grid>

        </Card>
    );
};

export default TaskListCard;