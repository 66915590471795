import Joi from 'joi';
const maxLength = 50
const commentSchema = Joi.string().label('Uwagi').max(200).allow('')
    .messages({
        'string.base': `Powinna być złożona ze znaków`,
        'string.max': `Nie mogą być dłuższe niż {#limit}`,
    })
export function commentValidator(value) {
    const validateResult = commentSchema.validate(value)
    if ('error' in validateResult) {
        return `${validateResult.error.message}`
    }
    return false
}
const plateNumberSchema = Joi.string().label('Nr rejestracyny').min(3).max(maxLength).empty().allow('').allow(null)
    .messages({
        'string.base': `Powinna być złożona ze znaków`,
        'string.empty': `Nie może być pusta`,
        'string.min': `Nie może być krótsza niż {#limit}`,
        'string.max': `Nie może być dłuższa niż {#limit}`,
        'any.required': `Pole jest wymagane`
    })
export function plateNumberValidator(value) {
    const validateResult = plateNumberSchema.validate(value)
    if ('error' in validateResult) {
        return `${validateResult.error.message}`
    }
    return false
}

const vinSchema = Joi.string().label('VIN').required().min(17).max(17).empty().allow('').allow(null)
    .messages({
        'string.base': `Powinna być złożona ze znaków`,
        'string.empty': `Nie może być pusta`,
        'string.min': `Nie może być krótsza niż {#limit}`,
        'string.max': `Nie może być dłuższa niż {#limit}`,
        'any.required': `Pole jest wymagane`
    })
export function vinValidator(value) {
    const validateResult = vinSchema.validate(value)
    if ('error' in validateResult) {
        return `${validateResult.error.message}`
    }
    return false
}


const carModelSchema = Joi.string().label('Model').min(1).max(maxLength).required().empty()
    .messages({
        'string.base': `Powinna być złożona ze znaków`,
        'string.empty': `Nie może być pusta`,
        'string.min': `Nie może być krótsza niż {#limit}`,
        'string.max': `Nie może być dłuższa niż {#limit}`,
        'any.required': `Pole jest wymagane`
    })
export function carModelValidator(value) {
    const validateResult = carModelSchema.validate(value)
    if ('error' in validateResult) {
        return `${validateResult.error.message}`
    }
    return false
}
const carBrandSchema = Joi.string().label('Marka').min(3).max(maxLength).required().empty()
    .messages({
        'string.base': `Powinna być złożona ze znaków`,
        'string.empty': `Nie może być pusta`,
        'string.min': `Nie może być krótsza niż {#limit}`,
        'string.max': `Nie może być dłuższa niż {#limit}`,
        'any.required': `Pole jest wymagane`
    })
export function carBrandValidator(value) {
    const validateResult = carBrandSchema.validate(value)
    if ('error' in validateResult) {
        return `${validateResult.error.message}`
    }
    return false
}

const emailSchema = Joi.string().label('Email').email({tlds:false}).max(50).required().empty()
    .messages({
        'string.email': `Email musi być prawidłowy`,
        'string.empty': `Nie może być puste`,
        'string.min': `nie może być krótsza niż {#limit}`,
        'string.max': `nie może być dłuższa niż {#limit}`,
        'any.required': `Pole jest wymagane`
    })
export function emailValidator(value) {
    const validateResult = emailSchema.validate(value)
    if ('error' in validateResult) {
        return `${validateResult.error.message}`
    }
    return false
}

const phoneSchema = Joi.string().label('Telefon').pattern(/^[0-9 +]+$/, 'numer telefonu').min(9).max(15).required().empty()
    .messages({
        'string.pattern.name': `Wymagane jest podanie cyfr telefonu`,
        'string.empty': `Nie może być puste`,
        'string.min': `nie może być krótsza niż {#limit}`,
        'string.max': `nie może być dłuższa niż {#limit}`,
        'any.required': `Pole jest wymagane`
    })
export function phoneValidator(value) {
    const validateResult = phoneSchema.validate(value)
    if ('error' in validateResult) {
        return `${validateResult.error.message}`
    }
    return false
}

const customerNameSchema = Joi.string().label('Klient').min(3).max(maxLength).required().empty()
    .messages({
        'string.base': `Powinna być złożona ze znaków`,
        'string.empty': `Nie może być pusta`,
        'string.min': `Nie może być krótsza niż {#limit}`,
        'string.max': `Nie może być dłuższa niż {#limit}`,
        'any.required': `Pole jest wymagane`
    })

export function customerNameValidator(value) {
    const validateResult = customerNameSchema.validate(value)
    if ('error' in validateResult) {
        return `${validateResult.error.message}`
    }
    return false
}

const generalSchema = Joi.object({
    customerName: customerNameSchema,
    phone: phoneSchema,
    email: emailSchema,
    carBrand: carBrandSchema,
    carModel: carModelSchema,
    carPlateNumber: plateNumberSchema,
    carVIN: vinSchema,
    comments: commentSchema,
    performerComment: commentSchema,
    salesRating: Joi.number().min(0).max(5).allow(''),
    chosenServiceId: Joi.number().allow(''),
    serviceDate: Joi.any().allow(''),
    dates: Joi.string().allow(''),
    status: Joi.any(),
    servicePlace: Joi.string().allow('isGarage','isMobile'),
    value: Joi.number().min(0),
    clientMode: Joi.string().allow('cPersonal','cSelect','cNew'),
    pia_client: Joi.number().allow(null),
    proposedDate: Joi.date().allow(null),
    proposedAsap: Joi.boolean().allow(null)
})
export function doesFormHasErrors(value) {
    // console.log(value)
    const validateResult = generalSchema.validate(value)
    if (validateResult && 'error' in validateResult) {
        return true
    }
    return false
}
