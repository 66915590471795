import React, { useState, useEffect, useLayoutEffect } from 'react';
import theme from '../../theme';
import {
  Dialog, DialogActions, DialogContent, DialogTitle,
  Card, CardContent,
  Box, Radio, RadioGroup, FormControlLabel,
  Button, Typography,
  CircularProgress, Alert,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import 'dayjs/locale/pl';

// uwaga zadanie może nie być jeszcze zapisane - zapisujemy rzerwacje w obiekcie serviceDate
const SelectServiceDate = ({
  serviceDate,
  customerName, phone, email, carBrand, carModel, carPlateNumber, carVIN,
  user, service, defaultSelectedService, isOpen, handleAcceptService, handleRejectService, proposedAsapX, proposedDateX }) => {
  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const [datesList, setDatesList] = useState();
  const [serviceSelected, setService] = useState(defaultSelectedService || 1);

  const [type, setType] = useState();
  const [proposedDate, setProposedDate] = useState();

  useEffect(() => {
    
    if (proposedAsapX == true) {
      console.log(`ładuje .... ASAP`)
      setType('tAsap')
      setProposedDate(null)
    } 
    if (proposedAsapX == false) {
      setType('tTerm')
      console.log(`ładuje .... nie ASAP ${proposedDateX}`)
      setProposedDate(dayjs(proposedDateX))
    }
    // console.log('select service date - useEffect',service)
    // loadReservationsAndSetFirst(user, pageNumber, pageSize) // na razie wyłączamy integrację
  }, [service]);

  const handleTermTypeChange = (event) => {
    setType(event.target.value)
  }


  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleRejectService}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"> Zaproponuj termin</DialogTitle>
        {isLoading && <CircularProgress sx={{ ml: 2 }} />}
        {errorMessage && <div><Alert severity="error">{errorMessage}</Alert></div>}
        <DialogContent>
          <RadioGroup
            row
            aria-labelledby="service-place-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={handleTermTypeChange}
            value={type}
          >
            <FormControlLabel value={"tAsap"} control={<Radio />} label="Jak najszybciej" />
            <FormControlLabel value={"tTerm"} control={<Radio />} label="Wybiorę datę" />
          </RadioGroup>
          {type == 'tTerm' ? (            
            <LocalizationProvider dateAdapter={AdapterDayjs}  adapterLocale="pl">
              <DateCalendar 
                value={proposedDate}
                onChange={(newValue) => setProposedDate(newValue)}
              />
            </LocalizationProvider>
          ) : ""}
        </DialogContent>
        <DialogActions>
          {
            defaultSelectedService !== serviceSelected ? (
              <Button onClick={() => handleRejectService()} autoFocus>
                Anuluj
              </Button>
            ) : ""
          }
          {!isLoading && !errorMessage && <Button onClick={() => handleAcceptService(type, proposedDate)}>Zapisz</Button>}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SelectServiceDate;