import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  CircularProgress,
  Button,
  CssBaseline,
  TextField,
  FormControl, FormGroup, FormControlLabel, Switch,
  Fade,
  Link,
  Grid,
  Box,
  Typography,
  Container,
} from '@mui/material';
import {
  passwordValidator
} from '../validators/ProfileValidator'
import { passwordReset, passwordResetVerify, passwordSet } from '../services/passwordReset'

import ReCAPTCHA from 'react-google-recaptcha';
const SITE_KEY = process.env.REACT_APP_SITE_KEY;

import Image from '../assets/soft99-garage.png';
import Logo from '../assets/logo_garage.svg';

export default function ResetPassword() {
  const navigate = useNavigate();
  const recaptchaRef = React.createRef();
  const { resetHash } = useParams()
  // statusy ładowania i zapisu
  const [isLoading, setIsLoading] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [success, setSuccess] = useState(0); // 0 - ładuję, 1- link ok, bład jakiś, 2-link nie ok, 3-udało się,

  // dane
  const [userId, setUserId] = useState();
  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');

  // sterowanie ekranem
  const [isFormRO, setIsFormRO] = useState(false);
  const [isValid, setIsValid] = useState(true)

  useEffect(() => {
    setIsLoading(true);

    const pathname = window.location.pathname // niestety nie działa resetHash from useParams
    const payload = {
      resetHash: pathname
    }
    // console.log(payload)

    passwordResetVerify(payload)
      .then(response => {
        if (response.status !== 'success') {
          // console.log(response.message)
          setErrorMessage(response.message)
          setSuccess(2)
          throw new Error(response.message);
        } else {
          // reload list
          setUserId(response.userId)
          // console.log(response.userId)
          setSuccess(1)
        }
      })
      .catch(error => {
        setErrorMessage(error.message);
        setSuccess(1)
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const handleSubmit = async e => {
    e.preventDefault();
    setErrorMessage('')
    setIsValid(true)
    setSuccess(1)

    const captchaToken = await recaptchaRef.current.executeAsync()
    
    const newPayload = {
      newPassword1,
      newPassword2,
      userId,
      captcha: captchaToken
    }
    console.log('newPayload',newPayload)

    // walidacja
    const thereAreErrors = passwordValidator(newPayload, false)
    console.log(thereAreErrors)
    setIsValid(!thereAreErrors)

    if (!thereAreErrors) {

      setIsLoading(true);
      await passwordSet(newPayload)
        .then(response => {
          if (response.status !== 'success') {
            console.log(response)
            setErrorMessage(response.message)
            setSuccess(1)
            throw new Error(response.message);
          } else {
            // reload list
            // setUser(user)
            setSuccess(3)
          }
        })
        .catch(error => {
          setErrorMessage(error.message);
          setSuccess(1)
        })
        .finally(() => {
          setIsLoading(false);
        });

    } else {
      setIsValid(false)
      setErrorMessage(thereAreErrors)
    }
    return
  }

  const onChange = async e => {
  }

  return (
    <Grid container spacing={1} sx={{ width: "100vw", height: "100vh" }}>
      <Grid item xs={12} sm={8} md={8} sx={{ height: '-webkit-fill-available' }}>
        <img src={Image} alt="budynek soft99 Garage nocą" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
      </Grid>
      <Grid item container sx={{ justifyContent: 'center', alignItems: 'center' }} xs={12} sm={4} md={4} >
        <Box sx={{ width: '60%' }} >
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <img src={Logo} alt="logo soft99 Garage" width={'90%'} />
          </Box>
          <Typography component="h1" variant="overline">
            PIA
          </Typography>
          <Typography component="h1" variant="h5" sx={{ mt: 5 }}>
            Reset hasła
          </Typography>

          {success == 1 && (
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, }}>

              <Box
                sx={{ mr: 1, minWidth: "100%" }}>

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password1"
                  label="Nowe hasło"
                  type="password"
                  // type='text'
                  autoComplete='new-password'
                  id="password1"
                  // helperText="Wprowadź nowe hasło"
                  onChange={(e) => setNewPassword1(e.target.value)}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password1"
                  label="Powtórz nowe hasło"
                  type="password"
                  id="password2"
                  autoComplete='new-password'
                  value={newPassword2 || ''}
                  // helperText="Powtórz nowe hasło"
                  onChange={(e) => setNewPassword2(e.target.value)}
                />
              </Box>

              <Button
                disabled={isFormRO}
                onClick={handleSubmit}
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}>
                Wyślij
              </Button>

              {isLoading && <CircularProgress sx={{ ml: 2 }} />}
              {errorMessage && <div><Alert severity="error">{errorMessage}</Alert></div>}

              <Grid container>
                <Grid item xs>
                  <Link href="/" variant="body2">
                    Strona logowania
                  </Link>
                </Grid>
              </Grid>

              <Grid>
                <ReCAPTCHA
                  className="recaptcha"
                  size="invisible"
                  data-theme="dark"
                  render="explicit"
                  sitekey={SITE_KEY}
                  ref={recaptchaRef}
                  onChange={onChange}
                />
              </Grid>
            </Box>
          )}

          {success == 2 && (
            <Grid container>
              <Grid item xs>

                <Typography component="h1" variant="subtitle1" sx={{ mb: 1, mt: 5 }}>
                  Wystąpił problem z dostępem do funkcji
                </Typography>
                {errorMessage && <div><Alert severity="error">{errorMessage}</Alert></div>}

                <Link href="/" variant="body2">
                  Strona logowania
                </Link>
              </Grid>
            </Grid>
          )}

          {success == 3 && (
            <Grid container>
              <Grid item xs>

                <Typography component="h1" variant="subtitle1" sx={{ mb: 1, mt: 5 }}>
                  Hasło zmienione, spróbuj się zalogować
                </Typography>

                <Link href="/" variant="body2">
                  Strona logowania
                </Link>
              </Grid>
            </Grid>
          )}

        </Box>
      </Grid >
    </Grid >
  );
}