import { red } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
// A custom theme for this app
const theme = createTheme({
  typography: {
    h5: {
      color: 'white'
    }

  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#E74C3C',
    },
    bar: {
      main: "#444444",
    },
    onWhite: {
      main: 'black'
    },
    // secondary: {
    //   main: '#0E4DA4',
    // },
    error: {
      main: red.A400,
    },
  },
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor: "rgb(232, 241, 250)",
        "&:hover": {
          backgroundColor: "rgb(250, 232, 241)",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "rgb(232, 241, 250)"
          }
        },
        "&.Mui-focused": {
          backgroundColor: "rgb(250, 241, 232)"
        }
      },
    MuiTextField: {
      backgroundColor: 'black',
      color: 'white',
      styleOverrides: {
        backgroundColor: 'black',
        root: {
          color: 'white',
          backgroundColor: 'black'
        }
      }
    }
    },


  }
});

export default theme;
