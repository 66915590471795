import React from 'react';
import { Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';


const NotFoundPage = () => {

  return (
    <div>
      <Typography variant="h1">
        404
      </Typography>
      <Typography variant="h4">
        Przepraszam ale strona której szukasz nie istnieje
      </Typography>
      <Typography variant="subtitle1">
        The page you are looking for does not exist.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        component={Link}
        to="/"
      >
        Go back to Home
      </Button>
    </div>
  );
};

export default NotFoundPage;