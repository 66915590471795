import React, { useState, useEffect } from 'react';
import { useNavigate, Link, useParams } from "react-router-dom";
import {
  Alert,
  CircularProgress,
  Button,
  CssBaseline,
  TextField,
  FormControl, FormGroup, FormControlLabel, Switch,
  Fade,
  Grid,
  Box,
  Typography,
  Container,
} from '@mui/material';
import {
  fornameValidator,
  lastnameValidator,
  phoneValidator,
  doesFormHasErrors
} from '../validators/ProfileValidator'
import { putUser } from '../services/profile'


export default function Profile({ user, setUser }) {
  const navigate = useNavigate();
  // statusy ładowania i zapisu
  const [isLoading, setIsLoading] = useState(user.id);
  const [errorMessage, setErrorMessage] = useState();
  const [success, setSuccess] = useState();
  // dane
  const [userId, setUserId] = useState(user.id);
  const [forname, setForname] = useState(user.forname);
  const [lastname, setLastname] = useState(user.lastname);
  const [phone, setPhone] = useState(user.phone);
  const [login, setEmail] = useState(user.login);

  const [informBySMS, setInformBySMS] = useState(user.informBySMS);
  const [informByEmail, setInformByEmail] = useState(user.informByEmail);
  const [informByPush, setInformByPush] = useState(user.informByPush);

  // sterowanie ekranem
  const [isFormRO, setIsFormRO] = useState(false);
  const [isValid, setIsValid] = useState(true)

  useEffect(() => {
    // console.log('TaskEdit-user', user)
    // console.log('TaskEdit-itemId', taskId)
    if (false) {
      loadProfile(user, taskId)
    } else {
      setIsLoading(false);
    }
  }, []);

  const loadProfile = async (user, ) => {
    setIsLoading(true);
    await getTask(user, taskId)
      .then(response => {
        // console.log(response)
        if (response.status == 'success') {
          setId(response.result.id)
          setForname(response.result.forname)
          setLastname(response.result.lastname)
          setPhone(response.result.phone)
          setEmail(response.result.email)
        } else {
          setErrorMessage('błąd pobierania danych...');
        }

      })
      .catch(error => {
        setErrorMessage(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  const handleSubmit = async e => {
    e.preventDefault();

    const newPayload = {
      forname,
      lastname,
      phone,
      informBySMS,informByEmail,informByPush
    }

    // walidacja
    const thereAreErrors = doesFormHasErrors(newPayload)
    setIsValid(!thereAreErrors)

    if (!thereAreErrors) {
      // call api and save or update

        setIsLoading(true);
        await putUser(user, newPayload)
          .then(response => {
            if (response.status !== 'success') {
              setErrorMessage(response.message)
              setSuccess(false)
              throw new Error(response.message);
            } else {
              // reload list
              user.forname = forname
              user.lastname = lastname
              user.phone = phone
              user.informByEmail = informByEmail
              user.informBySMS= informBySMS
              user.informByPush= informByPush
              setUser(user)

              navigate("/")
            }
          })
          .catch(error => {
            setErrorMessage(error.message);
            setSuccess(false)
          })
          .finally(() => {
            setIsLoading(false);

          });

    }
    return
  }

  return (
    <Box sx={{ ml: 1, display: "grid", justifyContent: 'center' }}>
      <Grid container spacing={1} sx={{ maxWidth: "960px", justifyContent: 'center', alignContent: 'flex-start' }}>

        {/* // tytuł */}
        <Box sx={{ display: "flex", flexGrow: 1, mt: 5 }} >
          <Typography sx={{ m: 1 }}>
            Dane użytkownika: {login}
          </Typography>
        </Box>

        {/* // pola */}
        <Grid item container sx={{ justifyContent: 'space-between' }} >

          <Box
            sx={{ mr: 1, minWidth: "100%" }}>
            <TextField
              sx={{ mr: 1, minWidth: "42%" }}
              margin="dense"
              required
              inputProps={{ "data-testid": "forname" }}
              id="forname"
              label="Imię"
              placeholder='np. Jan'
              name="forname"
              autoFocus
              disabled={isFormRO}
              value={forname || ''}
              error={fornameValidator(forname)}
              helperText={fornameValidator(forname)}
              onChange={(e) => { setForname(e.target.value) }}
            />
            <TextField
              sx={{ mr: 1, minWidth: "42%" }}
              margin="dense"
              required
              inputProps={{ "data-testid": "lastname" }}
              id="lastname"
              label="Nazwisko"
              placeholder='np. Nowak'
              name="lastname"
              disabled={isFormRO}
              value={lastname || ''}
              error={lastnameValidator(lastname)}
              helperText={lastnameValidator(lastname)}
              onChange
              ={(e) => { setLastname(e.target.value) }}
            />
          </Box>
          <Box
            sx={{ mr: 1, minWidth: "100%" }}>
            <TextField
              sx={{ mr: 1, minWidth: "42%" }}
              margin="dense"
              autoComplete="tel"
              inputProps={{ "data-testid": "phone" }}
              name="phone"
              label="Telefon"
              placeholder='np. 606 101 202'
              id="phone"
              disabled={isFormRO}
              value={phone || ''}
              error={phoneValidator(phone)}
              helperText={phoneValidator(phone,false)}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Box>

          <Box sx={{ mr: 2, minWidth: "100%" }}>
            <FormControl component="fieldset" variant="standard">
              <Box sx={{ display: "flex", flexGrow: 1, mt: 5 }} >
                <Typography sx={{ m: 1 }}>
                  Powiadamianie:
                </Typography>
              </Box>
              {/* <FormLabel component="legend">Powiadamianie</FormLabel> */}
              <FormGroup>
                {/* <FormControlLabel disabled control={<Switch checked={informBySMS} onChange={(event) => setInformBySMS(event.target.checked)} />} label="Informuj mnie o zdarzeniach przez SMS" /> */}
                <FormControlLabel control={<Switch checked={informByEmail} onChange={(event) => setInformByEmail(event.target.checked)} />} label="Informuj mnie o zdarzeniach emailem" />
                {/* <FormControlLabel disabled control={<Switch checked={informByPush} onChange={(event) => setInformByPush(event.target.checked)} />} label="Informuj mnie o zdarzeniach powiadomieniami" /> */}
              </FormGroup>
            </FormControl>
          </Box>
          
        </Grid>
        {/* // akcje */}
        <Grid item container sx={{ mt: 5, justifyContent: 'space-between', minWidth: "100%" }} >
          <Box
            sx={{ mr: 1, display: "flex", justifyContent: 'right', alignContent: 'flex-end' }}>

            <Button component={Link} to="/password" variant="outlined" sx={{ mr: 1, }}>
              Zmień hasło
            </Button>

            <Button component={Link} to="/" variant="outlined" sx={{ mr: 1 }}>
              Anuluj
            </Button>

            <Button
              disabled={isFormRO}
              onClick={handleSubmit}
              type="submit"
              variant="contained"
              sx={{ mr: 1 }}>
              Zapisz zmiany
            </Button>
          </Box>
        </Grid>
            <Fade
              in={!isValid} //Write the needed condition here to make it appear
              timeout={{ enter: 1000, exit: 1000 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
              addEndListener={() => {
                setTimeout(() => {
                  setIsValid(true)
                }, 2000);
              }}
            >
              <Alert severity="error" variant="standard" className="alert">
                Występują błędy walidacji
              </Alert>
            </Fade>
            {isLoading && <CircularProgress sx={{ ml: 2 }} />}
            {errorMessage && <div><Alert severity="error">{errorMessage}</Alert></div>}
            {success && <div><Alert severity="success">{success}</Alert></div>}

      </Grid>
    </Box>
  );
}