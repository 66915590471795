export async function getReport1(user, params) {
    const url = process.env.REACT_APP_API_URL + '/report1'
    // console.log(`loadTasks: ${url}`)
    // console.log(user.token)
    let result
    try {
        result = await fetch(url, {
            method: 'GET',
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'blob',
                'Authorization': `Bearer ${user.token}`
            }
        })
    } catch (err) {
        console.error(err)
        return {
            status: 'error',
            message: 'Błąd techniczny'
        }
    }

    switch (result.status) {
        case 200:
            return result.blob()
        case 500:
            return {
                status: 'error',
                message: 'Błąd działania aplikacji spróbuj ponownie później'
            }
        case 403:
            return {
                status: 'credential',
                message: 'Błędny użytkownik lub hasło'
            }
        case 401:
            return {
                status: 'credential',
                message: 'Sesja wygasła'
            }
        default:
            return {
                status: 'unknown',
                message: 'Nieznany błąd'
            }
    }
}