
import {
    Alert,
    CircularProgress,
    Button,
    Grid,
    Box,
    Typography,
    IconButton,
} from '@mui/material';
import theme from '../../theme';
import StationListCard from './StationListCard';

import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { getStations } from '../../services/station'

export default function Stations({ user, setUser }) {
    const [isLoading, setIsLoading] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [pageCount, setPageCount] = useState(0);
    const [stations, setStations] = useState([]);

    const navigate = useNavigate();
    useEffect(() => {
        loadStations(user, pageNumber, pageSize)
    }, []);

    // const handlePageChange = (pageNumber) => {
    //     loadCars(pageNumber, pageSize);
    // }

    const loadStations = async (user, pageNumber, pageSize) => {
        setIsLoading(true);
        // console.log('ładowanie stacji')
        await getStations(user, pageNumber, pageSize)
            .then(response => {
                // console.log(response)

                if (response.status == 'success') {
                    setStations(response.result.stations);
                    setPageNumber(response.result.pageNumber);
                    setPageCount(response.result.pageCount)
                    setPageSize(response.result.pageSize)
                } else {
                    setErrorMessage(response.message);
                }

            })
            .catch(error => {
                setErrorMessage(error.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }
    const [anchorEl, setAnchorEl] = React.useState(null);

    return (
        <Box sx={{ display: "grid", width: "100vw", justifyContent: 'center' }}>
            <Grid container spacing={1} sx={{ maxWidth: "960px", justifyContent: 'center', alignContent: 'flex-start' }}>
                {/* // pasek tytułowy i dodawanie */}
                <Grid item container sx={{ mb: theme.spacing(1), width: '70%', height: "5vw", justifyContent: 'center', alignItems: 'center' }}>
                    <Box sx={{ display: "flex", flexGrow: 1 }} >
                        <Typography variant="subtitle2">
                            {user?.stationId ? "Zmien lub zwolnij stanowisko pracy" : "Wybierz stanowisko pracy"}
                        </Typography>
                    </Box>
                </Grid>

                {/* // lista */}
                <Grid item container sx={{ justifyContent: 'center', alignItems: 'flex-start' }}>
                    {isLoading && <CircularProgress sx={{ ml: 2 }} />}
                    {errorMessage && <div><Alert severity="error">{errorMessage}</Alert></div>}

                    {(stations && stations.length > 0) ?
                        stations.map((station) => (< StationListCard key={station.id} item={station} user={user} setUser={setUser} />) //item, user
                        ) :
                        <Typography variant="subtitle2">
                            {(isLoading) ? "Trwa ładowanie danych..." : "Nie masz przydzielonych żadnych stanowisk. Skontaktuj się z adminem."}
                        </Typography>
                    }
                </Grid>
            </Grid>
        </Box>
    );
}