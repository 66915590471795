/** oczekiwana struktura
   [
            {
                name: "Myjnia 1",
                status: "wolne", // busy
                busyBy: "",
                busyFrom: "",
                lastWorkedDate: "2023-07-11 12:12",
                lastWorkedDiv: "23 minuty temu",
                class: "wash",
            },
            {
                name: "Myjnia 2",
                status: "zajęte", // busy
                busyBy: "Michał Antoniusz",
                busyFrom: "22h 43m",
                lastWorkedDate: "2023-07-11 12:12",
                lastWorkedDiv: "23 minuty temu",
                class: "wash",
            },
            {
                name: "Detailing",
                status: "zajęte", // busy
                busyBy: "Neron",
                busyFrom: "22h 43m",
                lastWorkedDate: "2023-07-11 12:12",
                lastWorkedDiv: "23 minuty temu",
                class: "detailing",
            },
            {
                name: "Master Detailer",
                status: "wolne", // busy
                busyBy: "Michał Antoniusz",
                busyFrom: "22h 43m",
                lastWorkedDate: "2023-07-11 12:12",
                lastWorkedDiv: "23 minuty temu",
                class: "master",
            }
        ]
 */

export async function getStations(user, pageNumber, pageSize) {
    const url = process.env.REACT_APP_API_URL+'/stations'
    let result
    try {
        result = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            }
        })
    } catch (err) {
        console.error(err)
        return {
            status: 'error',
            message: 'Błąd techniczny'
        }
    }

    switch (result.status) {
        case 200:
            return {
                status: 'success',
                result: await result.json()
            }        
        case 500:             
            return {
                status: 'error',
                message: 'Błąd działania aplikacji spróbuj ponownie później'
            }
        case 403:
            return {
                status: 'credential',
                message: 'Błędny użytkownik lub hasło'
            }
        default:
            return {
                status: 'unknown',
                message: 'Nieznany błąd'
            }
    }
}

export async function updateStation(user, mode, stationId) {
    const url = process.env.REACT_APP_API_URL + '/stations/' + stationId
    // console.log(`postTask: ${url}`)
    let result
    try {
        result = await fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
            },
            body:  JSON.stringify({ "mode": mode })
        })
    } catch (err) {
        console.error(err)
        return {
            status: 'error',
            message: 'Błąd techniczny'
        }
    }

    switch (result.status) {
        case 200:
            return {
                status: 'success',
                result: await result.json()
            }
        case 500:
            return {
                status: 'error',
                message: 'Błąd działania aplikacji spróbuj ponownie później'
            }
        case 403:
            return {
                status: 'credential',
                message: 'Błędny użytkownik lub hasło'
            }
        case 401:
            return {
                status: 'credential',
                message: 'Sesja wygasła'
            }
        default:
            return {
                status: 'unknown',
                message: 'Nieznany błąd'
            }
    }
}
