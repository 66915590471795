import React from 'react';
import theme from '../theme';
import {
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
  Divider,
  Button, Typography
} from '@mui/material';

const ReusableDialog = ({
  title,
  subtitle,
  children,
  rejectButton,
  acceptButton,
  isOpen,
  handleAccept,
  handleReject
}) => {

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleAccept}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"> {title} </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb:3}} id="alert-dialog-description"> {subtitle} </DialogContentText>
          {/* <Divider  /> */}
          <Typography variant="body2">
            {children}
          </Typography>
        </DialogContent>
        <DialogActions>
          {
            rejectButton ? (
              <Button onClick={handleReject} autoFocus>
                {rejectButton}
              </Button>
            ) : ""
          }
          <Button onClick={handleAccept}>{acceptButton}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReusableDialog;