import {
    Button,
    Box,
    Typography,
    AppBar,
    IconButton,
    Toolbar,
    Divider
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LocalCarWashIcon from '@mui/icons-material/LocalCarWash';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ManIcon from '@mui/icons-material/Man';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import Logo from '../assets/logo_garage.svg';
import theme from '../theme';
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import Notify from './Notify';

export default function Navbar({ user, setToken }) {

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfile = () => {
        setAnchorEl(null)
        navigate("/profile")
    }
    const handleReport = () => {
        setAnchorEl(null)
        navigate("/report")
    }

    const handleStation = () => {
        setAnchorEl(null)
        navigate("/")
    }

    const handleTasks = () => {
        setAnchorEl(null)
        navigate("/tasks")
    }

    const handleChangeStation = () => {
        setAnchorEl(null)
        navigate("/")
    }

    const handleLogout = () => {
        setAnchorEl(null)
        sessionStorage.setItem('token', null)
        setToken(null)
        navigate("/")
    }

    return (
        <AppBar position="sticky" sx={{ backgroundColor: theme.palette.bar.main }}>
            <Toolbar>
                <Box sx={{ display: "flex", flexGrow: 1 }} >
                    <img src={Logo} alt="logo soft99 Garage" />
                    <Divider orientation="vertical" flexItem sx={{ ml: 1 }} />
                    <Typography component="h1" variant="overline" sx={{ ml: 1 }}>
                        Wykonawca
                    </Typography>
                </Box>
                <Button aria-label="Zadania" onClick={() => navigate("/tasks")} >
                    <FormatListBulletedIcon color='action' />
                </Button>
                <IconButton
                    sx={{ fontSize: 40 }}
                    aria-label="change service"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    onClick={handleChangeStation}
                    color="white"
                >
                    {user?.station == 'wash' ? <LocalCarWashIcon fontSize="large" /> :
                        user?.station == 'detailing' ? <VisibilityIcon fontSize="large" /> :
                            user?.station == 'master' ? <ManIcon fontSize="large" /> :
                                <HelpOutlineIcon fontSize="large" />}
                </IconButton>

                {user && user.token && (
                    <Notify
                        user={user}
                    />
                )}
                {user && user.token && (
                    <div>
                        <Typography variant="subtitle1" component="div">{user.forname + " " + user.lastname}</Typography>
                    </div>
                )}

                {user && user.token && (
                    <div>

                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={handleTasks}>Zadania</MenuItem>
                            <MenuItem onClick={handleProfile}>Profil</MenuItem>
                            <MenuItem onClick={handleStation}>Stanowiska</MenuItem>
                            <MenuItem onClick={handleReport}>Raporty</MenuItem>
                            <MenuItem onClick={handleLogout}>Wyloguj</MenuItem>
                        </Menu>
                    </div>
                )}
            </Toolbar>
        </AppBar>
    );
}